export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            primary: '#0AC16A',
            'primary-variant-light': '#0AC16A',
            'primary-variant-highlight': '#0AC16A',
            'primary-variant-dark': '#0AC16A',
            'on-primary': '#193560'
          },
          surface: {
            surface: '#FFFFFF',
            'on-surface': '#193560',
            'on-surface-variant': '#666766',
            'surface-container': '#D2D2D2',
            'surface-container-lowest': '#F9F9F9',
            'surface-container-low': '#F4F5F7',
            'surface-container-high': '#E8EBEF'
          },
          inverse: {
            'inverse-surface': '#0B1D39',
            'inverse-on-surface': '#FFFFFF',
            'inverse-primary': '#0AC16A'
          },
          outline: {
            outline: '#FFFFFF',
            'outline-variant': '#FFFFFF',
            'outline-variant-dark': '#FFFFFF'
          },
          states: {
            error: '#C50E0E',
            'error-variant': '#FFE1E1',
            'on-error': '#FFFFFF',
            disabled: '#BEBEBE',
            'on-disabled': '#666766',
            success: '#0AC16A',
            'success-variant': '#F2FFF9',
            'on-success': '#FFFFFF',
            info: '#EFF9FF',
            'on-info': '#74CEFF'
          },
          vertical: {
            'on-product': '#FFFFFF',
            'on-product-variant': '#1E0C39',
            'life-insurance': '#1E0C39',
            health: '#1183C5',
            energy: '#671CC2',
            'home-loan': '#ED6A5A',
            'car-insurance': '#932189',
            others: '#525351'
          }
        }
      }
    }
  }
}
