
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexy_CbVny8b69t2gKNXvvLEL3MctY1OwG6rfGArVi3nVEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/index.vue?macro=true";
import { default as login7nCtZKDufjztGcxzEyPjwV4D1le_Jv4_45EPtI7SQFOOoMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verify_JAK6YbWTkDZs6CihS320OVFvqyKoOzbFHgUHdjsqC4Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemapiROzqmF82nqsXFDSj4_458_45n5VwUB8l5u3mPVkW_aGtoAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usXulRukIWnkA9h7P0KWKmBFRHEbHH11prjVi_45VoSvxr4Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registernqrA8sm0R_vFEiET_45zbKVNGXBam24bvba0cq91e1u38Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaintshT88Z_45dQVEBvHITujnUdLOXyVzc7juj5oAPu_gKzUXMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45us6Ul8PDcTGwnyT0tLS8LeqE_45_jj4gQIe780JYLWOBPbwMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as indexzKtbUItnG4umumXG3iq9pdKOcdiDY3_xN1imbb4G2uIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexMIK2_452pBIa_45jRWORmo4vnq_45w45mEyOx8I8fspLQ9v18Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93rNFT5L70qjoo18UXhzz6keFuA_zNHkqb5_FcpA3haZsMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnershipsRWbQj9AT6x3ZM14ocuyPXBFM9O9rQDlhWsuwR867y_458Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45userGNjcf9dxMfeed_8DE85TSPXJSCF570cKHbKYVchgMIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexhFW_45COp9UBcgMWHFsLyrrLA9S1NSx_45A_45PD4FJw3Bn8kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policyXhTAoXC7ewsCFpf11mM1CRW4yXQC6oF4sG6pEKre_VkMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordUY0vy82O6FKvnc2G_ZKCviGoO6xY0uT2dwAuSg6dYUQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as indexzK_451KSQVhV_khzTdIZ0mCzvHa4KSB_WWwwA8F49WQ8gMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexhm1r6lOuc0jrEdxiZbe_aSsydGVvSMFIOFBNIMVvtG4Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45passwordxIPUCpqAkv7ZDXOcq3MXGWeEuN9WUBdQxFTz5L6zZLMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as errorFIKVXAUWPpDqQFg49Rgs8FDsyHaz_45hE4vMNHfwYY03sMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as index_45yoWj2Llrgfbr9zwf3q2Lpp6I2pWH7qG4BHElmFY1EAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93Nxph3SDUz9zYONjr24xCpR5yPxfPT1Z8QqUQM9vXYnUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guides0VPiYN_45m43D5Jd0hWCxj78aNH19fKUaw590IUmIYMu0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticeG7EPJrT5Y9AddW9p8H29z6im0Yk8b7otNgpfmPR44NoMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as indexX67KxpJ94VykO2Ou0ZCOQ_45eUG9mCO_45_45jKsqPmueuAnsMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45passwordSR57HkQwG4VrXW7qmEA_klsSugiAGu3VbX_Yhdb74C8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backMF06inYsENdMxNtyu5HPCL1zMg0uNljtDDYaVcnpqjkMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as mergevk_45nEnB9Lmq_bU9oy26Ax_45YcXfdUibv_bz_xuGtNL_45UMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexR_45RcWFwliMmjDW9ohrQYrx6HH0Mqxn_4BMPm7sv6msgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_935uxaZVibyX8rD3SA6Gs2abl71yj0gJHuDYnkXlxZTD0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45you9ioXkmNNZ5zIwt7zF5_45XL5HLdwrX9Dys7aoGSosekK4Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youn2Dv1clwhtv3Mgo4upBagaw648OseRofaTyii4_iRQAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexBUIntQWqTzAwLs_45aNkV_45Fx5IWMuFYOog7mlMHG6UHrUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexueFLE2aU9sMR1R0QcAMF2rDGgvknppuXt_X_nqKqNUgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_936272Fx2_MFjPbAjuUo_45EQVAcqM9elDh8aL6OJ755wAAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexb3godLedXIDf__8x0h_45MRCzZP6KU_QIqtD9wmi_jD1AMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexJ_nrO5aJ7t_45nCBw0DOBzFaEooaduWDGo9Opafsd3dUQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93VOPYdXo05cFX6ecnqSIXViIIf2uwiAk7bAOjeceRyGMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackuGUInI7FzXdrDprs5MqSg1l7nVDduGH_uHifr2tf3WUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimU0RGYEIeEkOozz4Hl4s_zbxS4B9ogCvkoL1XCF9X4dgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexh7evouvECnh25w0mYGUckyMtjviHrBAwWy2Okp4gWnYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45youeE3YlJKoSzHR5_JZGggE3skwinewMRowN5FLS8SWiOgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errorJZUPzdrADAtTMQxp763OWcvNtQ2bA7MTe9wIdygdh1oMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexWoeHV3rAOUXFGOgZFOyK_451NGbziTkT_45rpVgX_453s4agAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as index0ggo0PnVF83KvJgneOn0BBZw_45FiV5sLUURiEDxGGKM8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as error6ux29u0K9mw4F0ZO4YbYW_7gz6IdHbtSR_prqVI6XroMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93A0HHxB39XZVa65mnphnx3wH494jsqM1XbxazhMPtftcMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as detailswT_GgPl_eyqnJuBMN0pPYpBTMk0YvPF3cdFG9DGX8j4Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesQLOg2kDqkXGQc1p5YdD1NN2WreN_45onRYDL9vdWkqpa0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93uCo_45bI_I_452llsyw2KqvMJg7_fdQjw7F6KpVSvBJ8EygMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexLVO4TgqENzrGGABoB0fkJC4SddB_PldecBOfnJpt6ZoMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingse_N6jIRf2eOg083WH2fmLfoXB8S1BelJEGZJCw6BJ1MMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as index0E_q4s43JlA8k9ImJj88LjtgXG0GqYtrUGMdmI2PSnIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatpLpKpwc3wZu_OvYuhcKuWh6eD408Gxk3jtmWorX5BE8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45salem04fPad8WE2Ot6cs3MD0MkOSH5FcUjmIPZhCTXQGd0YMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45you_KmMaCjEjH0UU2nBdJN1yyW8Qye1_SnuGU9VqhyOES8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensiveUQLlV8JdQIJ3ypdBH9pj0k0486RPoRsYuYYIrqXBD5kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93kyV0RxjGYQuXebo2SH2uXwNuZ4nzS3hlWGJ1hLZVy1kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addiTlSvO13w2TiiiGr2ussUAVrThDi7Hi4IhB_45zzgpVyIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexsmhO89Htq7fT7eXi54VRLXnrIjPb24Am86n7xOCNtwIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_938S8WLB5tLFDrnLWhWkCH_zcjFRf7yiA93ZmRhNQTaAIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callback_DGukikmZFgA6FsXLZr0fRamWkwVYVvDfEo20fl4u1gMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93vo1ruZsohb6qv17_3lFloU_45F10L835cCvJxiwBKixjgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youwjDQIk81VdeKtTHG_6lphUlC5X9Waj_45biivdIspUwcEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45bSI0lR9CvogD05AWnWbZmEVLdHUTcb536Zck0hie_45fzUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexOF3DV0ofVWu82saOW9Hnhzh0fnng_TeKWyBX4TtzVtEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93ZBd__45Za7zAUQdm_V4CYBQ_l_45XNQJGkiCtridpARNpO8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93JNomqLnIdrr4KD6NAzeHjODKxKwWVEzCjMkJtBriZSMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexCNdfWcQg4_gwPYrxtiUGDhixt1Bh_45pn8Io_zAHauchwMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addJQR5lnD9D062nlNJXRW7evcJId1hpJhmkoL1jLJui5QMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93tXcWhq7ilHXGlubqnbPODzigOlIHWNOJb5mOYIIzxnMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as details9m_fwJstl10fCXGqw82fx_45SLxFMKYJICAs23DFaNXfQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackmOCD1b7ayLxTWcrvfVcc_grZXeAgfgW6U2hxHuOOXZwMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eicv4GkPJ2DyrUcjUr1OIFxpg2aQKPpFGEYoe0uJoAdlMYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45you0_45U0ewDEo0juyH6ovNd78u244xdnWYrAvCgC7LIhpe8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93gaG9Z4PZWL_t2u0aaTf6O97867ByjgTpHNGTwKxXT00Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93ofEJtfECPdF3ZvsCpMwNzcqwCaZH_45qY28t80kXXJHyQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisonPt3n1kEzNOGw_45g8PerxYAbtMJezOzXNXPPyWRl_NxsEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1UryIZLu4ssmSE1ZnPKcNCmdieiSEhnCr5jb6C290AusMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as details90gMTrWsDKNrd_QixlodZaCpovjVD1FRtrWN1_Ch8zIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultspMqOLb8ijdkM4X4j14_45kqouXU2JCPKfkMuNdflp6_45d0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexZ97Iez8EyHC6u2ApvxXpxWVLnvXegO_45C_tqB2Jb86cAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signup4d0a9vDw1JE6GGhOOqXIB_zG_45oLbyN0fObzVOTt60tQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45youJNDPr7lvPqGHqaGUDVCteg1TbylN0kTz9jCZMPjWsv0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93rex1gtp_77dHUpsnbR5WZ6KqGOSAPgWOiD0xE0Favd8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93Q_brIM89favcZyH0AUnvsO1xibQeFgWW9JskefA22agMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0dySoAJeaY89i9ztxpqB0zKx3155JOth7IFFjVqeDAHAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customersObpP_45T_OnAyhtO0kGYumCE9SB_OfL5ePamKIV6jWOWUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youQqoIZ3Ur_Vl0FuWJdckyvoA_VV_EfwDWOTbTRapA2XIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsx2Wu0L0_45SnR30Xzb23kBMV5GUltjGVcnkIz_457ZgJNocMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailstl2IghLiYu8K_F_45EdUN5A3bvgAfiLIx2MNGP0qncaBkMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93wtRwpDjGsJPL9iv6SzDuxwR7trK0UrAhIx_45W509qjyYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateX9272y_cyOJr9AvoLjiwfEHPEfzQv8r6PrZ42QPb4M0Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_935jQuTuVyCEKFe53gofVcmrZcg3W0bS_tzPwpiwV0dbYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as detailsD_453x2zaStBLmlsQdPbCu_RDiFNc26_45UYsCxiuikv7CYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step0Z_45XY9OVYxp_450KEdMl1_45RZ3_4I6UcyMBx_vaJWQq7xoMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexRz5ufvZ1ihcPelRlPTGo6CltxbvO041dJbRI1kjz73QMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexESi_BpNKUI_45cI_PnJ9WidPK5tq_37ybItvkO1uivB0sMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailszPgoMc_456Zu8iw_45F5ziiSQWUSZtZzlv_45vlUmrcb0_45zygMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditions4Nd8rb6AJgIAiA5ZUZYxMU2rulaxm1rfFq_eBrx9g7AMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93aT9J_7Q_45uhvPJcdJ6TZDuc_emDqvT7HUdr5dsMLhbcYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as indexaeJjwCT_m8XJuL_45SRBKJPtJVIDGtr9ff_456rNXJD1o50Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as detailsqyI_45m2wJvEJeUE12OS8_lE3ZXFKwMilVLac5qVKtgXwMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexV2cqxscA2Y9u_s0NYwFhQRrxjUW4T9PJdKASfew9OVUMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successKYi3Js9qp9Z7KLwj1TVWLU7Ap_9D2QtAH7_j_AHdz7AMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offer4kyVn3pcDlnnilmvERrMiXu8XGgcfg9g5pXmNByWj5QMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsFIMD_45HqiVEAfXYmHS_SuGx3_qlqvKY13f5_45SCfKgV1gMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbour6YtoVSqgzJHxcJ44Ema8OhL4f_45UG0vK4QKCOhcs_ShIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93wzFbBfbJodfN_45xK2fx4T9tzHRFxeL_E7YfX_45boC0CQsMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as indexMl08txun7WALPizqcXwYdQphYTd2_45k0XLTwJ3SKoBpkMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93UIAm4bBhhvXFRGU5lj96DMIQqx2aGdJVm8xR8PJq9n8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexHQJe8n2Mx7969YywNFjXidpKduAPeq63LxpUT7mbFY8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_935Gic7n4dLXUjMWtB8Wj0jbos4Sy9wNIdlO0q_0VQgpMMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45youoDrGnwekYRbHrP1XpfOxEETGj2ZJb26vxqeUXLZeP1gMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexRoWpRNj0I19eSKQunrAtk_iMnM_45f38t9V8TWGJ6Wz7kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexDC662akJwSmDx9LRyXR49GXAqYux6MoWFwGoXKuVveIMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsiF9hhX3ombIBMqabfOmVc56_QAdmn1pPc_45qe8FAAk24Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expired_45LXy_45reKEdDbZrDJvkta3TXTJ9HgJBwgSwM8VwMYCGEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexEsuDQ15iP2fQZ8h_45ojxhkKFZV0IldWDwfZRYHdZu7yYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45you3BX94ZPCdq0VHoezCNgKdYLRo6AnZjnEAHERc6iSxIAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsV1KQZfTw0DyW0jD_JUO1lfH8ZpXp2eeyrzvwEdvCi6kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentldoSXbm1ZRUNjdr_455HWicX_1R4Gf_45nIL_45KP90I_453jpcMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationhg0PPScFG5icS_TPpYY3Ukb9UhfcICErM_Qmv1k5ihAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionl_PrCfbwThAwUp_45SzaSCK3wAjfoUJnZ4jss0Yltrg54Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45results_45p95aVum0zoqvqIZswI_pZzsLkTTeJol7a4lkfmHQlEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationLgK9VmbRHv67m4F1LX_VozlP6A8a6r7l5pRez0YXwp8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93vovzRbBg7jfGSBqHsIMMHJIrLUlG_458h81q3uQooXNEsMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_93ZjJcg_45tojuXSQ2EPdeX0Vmas5m_45ZPF9gL_WSBLKmKEEMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93bdxnjbewPKM55xKvCmvAMdHstrr129lcVU78Tz04DSAMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93_45RWVNoaFBGn6AbOhcDTPX0kEB4y0CKQ3qWNIRICBGC8Meta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexqvfrO8lxDcTZTAK0_45N6AUkl8K_459b_45WDkHjCD7bbtwhQMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45details_45efC9X1EffW4wXJdMHIcXsh88_45WvAScdfiMeggJw_45UoMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_93DOk1oSfvGfIcE06fc_1vX_45wgnw_AcPwn6sETXHnvlyYMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93hzA4KrbHmpTHXx5wVi6o0zsLqx_45_45FQte3PDhq3eRf6kMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexDY2TlAww0EzmerRoT3oW63JZctBbnzntjzQSR7WVijgMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as index9BgDMwDCAuvWL1tIzVds3ql1S4xjPz2qXcRO2kwZs7gMeta } from "/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login7nCtZKDufjztGcxzEyPjwV4D1le_Jv4_45EPtI7SQFOOoMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registernqrA8sm0R_vFEiET_45zbKVNGXBam24bvba0cq91e1u38Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordUY0vy82O6FKvnc2G_ZKCviGoO6xY0uT2dwAuSg6dYUQMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexhm1r6lOuc0jrEdxiZbe_aSsydGVvSMFIOFBNIMVvtG4Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordxIPUCpqAkv7ZDXOcq3MXGWeEuN9WUBdQxFTz5L6zZLMMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45passwordSR57HkQwG4VrXW7qmEA_klsSugiAGu3VbX_Yhdb74C8Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_935uxaZVibyX8rD3SA6Gs2abl71yj0gJHuDYnkXlxZTD0Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexb3godLedXIDf__8x0h_45MRCzZP6KU_QIqtD9wmi_jD1AMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexh7evouvECnh25w0mYGUckyMtjviHrBAwWy2Okp4gWnYMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: index0ggo0PnVF83KvJgneOn0BBZw_45FiV5sLUURiEDxGGKM8Meta?.props ?? {"verificationNeeded":true},
    meta: index0ggo0PnVF83KvJgneOn0BBZw_45FiV5sLUURiEDxGGKM8Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingse_N6jIRf2eOg083WH2fmLfoXB8S1BelJEGZJCw6BJ1MMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: index0E_q4s43JlA8k9ImJj88LjtgXG0GqYtrUGMdmI2PSnIMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callback_DGukikmZFgA6FsXLZr0fRamWkwVYVvDfEo20fl4u1gMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateX9272y_cyOJr9AvoLjiwfEHPEfzQv8r6PrZ42QPb4M0Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailszPgoMc_456Zu8iw_45F5ziiSQWUSZtZzlv_45vlUmrcb0_45zygMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offer4kyVn3pcDlnnilmvERrMiXu8XGgcfg9g5pXmNByWj5QMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsFIMD_45HqiVEAfXYmHS_SuGx3_qlqvKY13f5_45SCfKgV1gMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93wzFbBfbJodfN_45xK2fx4T9tzHRFxeL_E7YfX_45boC0CQsMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionl_PrCfbwThAwUp_45SzaSCK3wAjfoUJnZ4jss0Yltrg54Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93vovzRbBg7jfGSBqHsIMMHJIrLUlG_458h81q3uQooXNEsMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_93ZjJcg_45tojuXSQ2EPdeX0Vmas5m_45ZPF9gL_WSBLKmKEEMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93_45RWVNoaFBGn6AbOhcDTPX0kEB4y0CKQ3qWNIRICBGC8Meta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45details_45efC9X1EffW4wXJdMHIcXsh88_45WvAScdfiMeggJw_45UoMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_93DOk1oSfvGfIcE06fc_1vX_45wgnw_AcPwn6sETXHnvlyYMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93hzA4KrbHmpTHXx5wVi6o0zsLqx_45_45FQte3PDhq3eRf6kMeta || {},
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src2632774996/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]