export default {
  theme: {
    extend: {
      colors: {
        semantic: {
          primary: {
            primary: '#361564',
            'primary-variant-light': '#671CC2',
            'primary-variant-highlight': '#614983',
            'primary-variant-dark': '#1E0C39',
            'on-primary': '#FFFFFF'
          },
          surface: {
            surface: '#FFFFFF',
            'on-surface': '#1E0C39',
            'on-surface-variant': '#8C8C89',
            'surface-container': '#E2E1E0',
            'surface-container-lowest': '#FBF7FF',
            'surface-container-low': '#EFE8F8',
            'surface-container-high': '#E1D2F3'
          },
          inverse: {
            'inverse-surface': '#1E0C39',
            'inverse-on-surface': '#FFFFFF',
            'inverse-primary': '#CBFE50'
          },
          outline: {
            outline: '#C5C5C2',
            'outline-variant': '#E2E1E0',
            'outline-variant-dark': '#1E0C39'
          },
          states: {
            error: '#A3000B',
            'error-variant': '#FFF7F8',
            'on-error': '#FFFFFF',
            disabled: '#F1F0F0',
            'on-disabled': '#8C8C89',
            success: '#00A657',
            'success-variant': '#F7FFFB',
            'on-success': '#FFFFFF',
            info: '#EFE8F8',
            'on-info': '#1E0C39'
          },
          vertical: {
            'on-product': '#FFFFFF',
            'on-product-variant': '#1E0C39',
            'life-insurance': '#1E0C39',
            health: '#1183C5',
            energy: '#671CC2',
            'home-loan': '#ED6A5A',
            'car-insurance': '#932189',
            others: '#525351'
          }
        }
      }
    }
  }
}
